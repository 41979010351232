<template>
  <b-card-code title="Events" no-body v-if="!isFetching">
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group label-align-sm="left" label-cols-sm="2" label-for="sortBySelect">
          <h5>Sort</h5>
          <b-input-group>
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
              <template #first>
                <option value="">None</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" :disabled="!sortBy">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group>
          <h5>Season</h5>
          <b-input-group>
            <b-form-select id="temporada" v-model="temporadaSelected" :options="teporadaOptions">
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <h5>Aforo</h5>
          <b-input-group>
            <b-form-select id="aforo" v-model="aforoSelected" :options="aforoOptions">
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <h5>Search</h5>
          <b-input-group>
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <h5>Dates From-To</h5>
          <b-input-group>
            <b-form-group>
              <flat-pickr v-model="rangeDate" class="form-control"
                :config="{ mode: 'range', enableTime: true, time_24hr: true }" />
            </b-form-group>
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <h5>Active Filter</h5>
          <b-input-group>
            <b-form-select id="activeEvents" v-model="selectedStatus" :options="statusOptions">
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <h5>Set Filters</h5>
          <b-input-group>
            <b-button variant="primary" @click="filterResults()" class="btn-icon">
              <feather-icon icon="FilterIcon" />
            </b-button>
          </b-input-group>
        </b-form-group>

        <b-form-group v-if="$can('create', 'AddEvent')">
          <h5>Add</h5>
          <b-input-group>
            <b-button variant="primary" to="/events/create" class="btn-icon">
              <feather-icon icon="PlusCircleIcon" /> New Event
            </b-button>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table borderless striped hover responsive class="position-relative" :per-page="perPage"
      :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn">
      <template #cell(Modify)="data" v-if="$can('edit', 'EditEvent')">
        <b-button variant="flat-primary" :to="{ name: 'edit-event', params: { id: data.item.pkid_mxs_event } }"
          class="btn-icon" size="sm">
          <feather-icon icon="EditIcon" size="24" />
        </b-button>
      </template>
      <template #cell(URL)="data" v-if="$can('generate', 'GenerateURL')">
        <b-button variant="flat-primary" class="btn-icon" size="sm" @click="doCopy(data.item.pkid_mxs_event.toString())">
          <feather-icon icon="PaperclipIcon" size="24" />
        </b-button>
      </template>
      <template #cell(Persons)="data" v-if="$can('read', 'Persons')">
        <b-button variant="flat-primary" :to="{
          name: 'persons',
          params: { idEvent: data.item.pkid_mxs_event },
        }" v-b-modal.modifyEvent class="btn-icon" size="sm">
          <feather-icon icon="UsersIcon" size="24" />
        </b-button>
      </template>
      <template #cell(Date)="data">
        {{ dateFormat(data.value) }}
      </template>
      <template #cell(mxs_event_active)="data">
        <b-badge :variant="activeLabel[1][data.value]">
          {{ activeLabel[0][data.value] }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1">
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable,BAvatar,BBadge,BFormGroup,BFormSelect,BPagination,BInputGroup,BFormInput,BInputGroupAppend,BButton,BCardBody } from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { codeKitchenSink } from "../code";
import flatPickr from "vue-flatpickr-component";
import axios from "@/libs/axios";
import { initialAbility } from "@/libs/acl/config";
import { decryptString, encryptString } from "@/libs/crypto";

export default {
  components: {
    BCardCode, flatPickr, ToastificationContent,
    BTable,BAvatar,BBadge,BFormGroup,BFormSelect,BPagination,BInputGroup,BFormInput,BInputGroupAppend,BButton,BCardBody,
  },
  computed: {
    teporadaOptions() {
      const tempos = this.temporades.map((t) => ({ text: t, value: t }));
      return tempos;
    },
    aforoOptions() {
      const afs = this.aforos.map((t) => ({ text: t, value: t }));
      afs.unshift({ text: "All", value: "" });
      return afs;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => (
          { text: f.label, value: f.key }
        ));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  methods: {
    filterResults() {
      let request = "/event?";
      let count = 0;
      let tn = true;
      let an = true;
      let sn = true;
      let dfn = true;
      let dtn = true;

      if (this.rangeDate) {
        const dates = this.rangeDate.split(" to ");
        this.dateFrom = dates[0];
        this.dateTo = dates[1];
      }

      if (count === 0) {
        if (this.temporadaSelected) {
          request += "Temporada=" + this.temporadaSelected;
          count++;
          tn = false;
          localStorage.setItem('temporadaSelected', this.temporadaSelected)
        } else if (this.aforoSelected) {
          request += "Aforo_name=" + this.aforoSelected;
          count++;
          an = false;
          localStorage.setItem('aforoSelected', this.aforoSelected)
        } else if (this.selectedStatus) {
          request += "mxs_event_Active=" + this.selectedStatus;
          count++;
          sn = false;
          localStorage.setItem('selectedStatus', this.selectedStatus)
        } else if (this.dateFrom) {
          request += "Date_from=" + this.dateFrom;
          count++;
          dfn = false;
          localStorage.setItem('dateFrom', this.dateFrom)
        } else if (this.dateTo) {
          request += "Date_to=" + this.dateTo;
          count++;
          dtn = false;
          localStorage.setItem('dateTo', this.dateTo)
        }
      }

      if (count !== 0) {
        if (this.temporadaSelected && tn) {
          request += "&Temporada=" + this.temporadaSelected;
          localStorage.setItem('temporadaSelected', this.temporadaSelected)
        }
        if (this.aforoSelected && an) {
          request += "&Aforo_name=" + this.aforoSelected;
          localStorage.setItem('aforoSelected', this.aforoSelected)
        }
        if (this.selectedStatus && sn) {
          request += "&mxs_event_Active=" + this.selectedStatus;
          localStorage.setItem('selectedStatus', this.selectedStatus)
        }
        if (this.dateFrom && dfn) {
          request += "&Date_from=" + this.dateFrom;
          localStorage.setItem('dateFrom', this.dateFrom)
        }
        if (this.dateTo && dtn) {
          request += "&Date_to=" + this.dateTo;
          localStorage.setItem('dateTo', this.dateTo)
        }
      }

      axios
        .get(request)
        .then((results) => {
          this.items = results.data;
          this.totalRows = this.items.length;
        })
        .catch((error) => {
          const variant = "danger";
          this.$bvToast.toast("Failed to connect", {
            title: `` + error,
            variant,
            solid: true,
          });
          if (localStorage.getItem("accessToken") === null) {
            this.$ability.update(initialAbility);
            this.$router.push({ name: "login" });
          }
        });
    },
    dateFormat(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const minutesArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      let normalDate = date.split("T").join(" ");
      normalDate = date.split("Z").join("");
      const d = new Date(normalDate);
      let minutes = d.getMinutes();
      if (minutesArray.includes(d.getMinutes().toString())) {
        minutes = "0" + d.getMinutes();
      }
      const finalDate =
        d.getDate() +
        " " +
        months[d.getMonth()] +
        " " +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        minutes;
      return finalDate;
    },
    doCopy(id) {
      const currentURL = window.location.origin;
      this.$copyText(currentURL + '/import-persons/' + encryptString(id)).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'URL copied',
            icon: 'BellIcon',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
  },
  data() {
    return {
      isFetching: true,
      perPage: 50,
      pageOptions: [50, 100, 200, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: "event_name",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        { class: "colModify", key: "Modify", label: "" },
        {
          class: "colStyle",
          key: "pkid_mxs_event",
          label: "Id",
          sortable: true,
        },
        { class: "colURL", key: "URL", label: "URL" },
        { class: "colPersons", key: "Persons", label: "Persons" },
        {
          class: "colStyle",
          key: "Temporada",
          label: "Season",
          sortable: true,
        },
        {
          class: "colStyle",
          key: "Aforo_name",
          label: "Aforo",
          sortable: true,
        },
        {
          class: "colStyle colEventName",
          key: "event_name",
          label: "Event",
          sortable: true,
        },
        {
          class: "colStyle",
          key: "Competition_name",
          label: "Comp.",
          sortable: true,
        },
        {
          class: "colStyle colDate",
          key: "Date",
          label: "Date",
          sortable: true,
        },
        {
          class: "colStyle",
          key: "mxs_event_Active",
          label: "Active",
          sortable: true,
        },
      ],
      items: [],
      activeLabel: [
        {
          false: "Inactive",
          true: "Active",
        },
        {
          false: "light-danger",
          true: "light-success",
        },
      ],
      selectedStatus: "true",
      statusOptions: [
        { value: "true", text: "Active" },
        { value: "false", text: "Inactive" },
      ],
      codeKitchenSink,
      temporades: [],
      temporadaSelected: "",
      aforos: [],
      aforoSelected: "",
      dateFrom: "",
      dateTo: "",
      rangeDate: ""
    };
  },
  async created() {
    await axios
      .get("/event?ID_Aforo=" + decryptString(localStorage.getItem('Aforo_permit')))
      .then((results) => {
        this.initialItems = results.data;
        this.totalRows = this.initialItems.length;

        this.initialItems.forEach((element) => {
          this.temporades.push(element.Temporada);
        });
        this.temporades = this.temporades.filter((item, index) => {
          return this.temporades.indexOf(item) === index;
        });
        this.temporades.reverse();
        this.temporadaSelected = this.temporades[0];

        this.initialItems.forEach((element) => {
          this.aforos.push(element.Aforo_name);
        });
        this.aforos = this.aforos.filter((item, index) => {
          return this.aforos.indexOf(item) === index;
        });
        this.aforos.sort();
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      });

    const request1 = "/event?Temporada=" + this.temporadaSelected + "&mxs_event_Active=" + this.selectedStatus + "&ID_Aforo=" + decryptString(localStorage.getItem('Aforo_permit'));

    // if (localStorage.getItem('aforoSelected')) {
    //   request1 += 
    // }

    await axios
      .get(request1)
      .then((results) => {
        this.items = results.data;
        this.totalRows = this.items.length;
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      });

    this.isFetching = false;

  },
  mounted() {
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.colStyle {
  font-size: small;
}

.colModify {
  /* display: none; */
  width: 0px;
}

.colURL {
  width: 0px;
}

.colPersons {
  width: 0px;
}

.colEventName {
  width: 500px;
}

.colDate {
  width: 200px;
}
</style>
